import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Unordered from "~/components/List/Unordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={6}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 6</H2>

          <p>You probably found several things in the lemonade diet cleanse website that made you think twice about whether it is believable. You probably can think of some other ads or commercials that you have seen that also raise questions.</p>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_3_step_6.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>By developing good critical thinking skills, you are better prepared to make decisions. Someone with critical thinking skills is able to do the following:</p>

          <Unordered>
            <li>understand the logical connections between ideas</li>
            <li>identify, construct, and evaluate arguments</li>
            <li>detect inconsistencies and common mistakes in reasoning</li>
            <li>solve problems systematically</li>
            <li>identify the relevance and importance of ideas</li>
          </Unordered>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
